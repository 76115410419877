let Map = {

    ChoiceStore: (postcode = false, callback) => {

        let moment  = require('moment');
        let now     = moment().format('YYYY-MM-DD');

        if (J('#qsuppliers_map').length > 0) {

            var map = L.map('qsuppliers_map').setView([43.3, 5.4], 7);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: ''}).addTo(map);

            if (J('.qsuppliers-item').length > 0) {

                var markers = [], itemMarkers = [], itemLength = J('.qsuppliers-item').length, count = 0;

                function markerFunction(id) {
                    for (var i in itemMarkers) {
                        var markerID = itemMarkers[i].options.title;
                        if (markerID == id) {
                            itemMarkers[i].openPopup();
                        }
                    }
                }

                J('.qsuppliers-item').hide();
                J('.qsuppliers-label').find('span').text(postcode);
                J('#_qsuppliers_poscode_search').val(postcode);

                let datetime    = new Date().toISOString();
                let date        = datetime.split('T')[0];
                let hours       = (datetime.split('T')[1]).substring(0, 5);
                    hours       = parseInt(hours.replace(':', ''));

                J('.qsuppliers-item').each(function (index, item) {

                    // >> -- Code postaux de livraison --
                    let postcodeAttr        = $(item).attr('available-postcode');
                    let arrayPostcode       = postcodeAttr.split(',');

                    // >> -- Code postaux à emporter --
                    let postcodeStoreAttr   = $(item).attr('available-postcode-store');
                    let arrayPostcodeStore  = postcodeStoreAttr.split(',');

                    // >> -- Livraison possible ? --
                    let deliveryAttr    = $(item).attr('delivery');
                    let delivery        = deliveryAttr.toString();
                    let limit           = false;

                    if (J(item).attr('is-available') === '1') { // Si le produit est disponible dans cette boutique

                        // >> --Si la boutique fait des livraisons, mais que le code postal de livraison n'est pas disponible, on bloque la livraison --
                        if (arrayPostcode.indexOf((postcode.toString())) === -1 && delivery === '1') {

                            $(item).children('.item-delivery-container').children('.item-to-go').hide();

                            $(item).children('.item-delivery-container').children('.item-delivery').removeClass('item-delivery-border').html(
                                `
                                <div>RETRAIT EN BOUTIQUE</div>
                                <div><input type="radio" name="qsuppliers_delivery" value="0"></div>
                                <small>Livraison possible pour cette boutique, mais pas dans votre arrondissement</small>
                                <!--<div>Zone de livraison disponible</div><div class="item-delivery-postcode">${(postcodeAttr.replaceAll(","," - "))}</div>-->
                                `
                            );
                        }
                        // <<

                        // >> -- Jour de fermeture
                        let closedDay = $(item).attr('closed-day');

                        if (closedDay) {

                            let closedDayArray = closedDay.split(',');
                            
                            if (closedDayArray.indexOf(now) != -1) {
                                $(item).children('.item-info').append(`
                                    <div class="store-closed-container">
                                        <div class="store-closed-red">FERMÉ Ajourd'hui</div>
                                        <div class="store-closed-notice">Les commandes ne seront pas disponibles pour les jours de fermeture.</div>
                                    </div>
                                `);
                            }
                        }

                        // >> -- Jour de non livraison
                        let closedDayDelivery = $(item).attr('closed-day-delivery');

                        if (closedDayDelivery) {

                            let closedDayDeliveryArray = closedDayDelivery.split(',');
                            
                            if (closedDayDeliveryArray.indexOf(now) != -1) {
                                $(item).children('.item-delivery-container').children('.item-delivery').html(`
                                    <div class="open-date-delivery-container">
                                        <div>PAS DE LIVRAISON</div>
                                        <small>Les commandes ne sont pas disponlible pour la livraison aujourd'hui</small>
                                    </div>
                                `);
                            }
                        }

                        let lat = parseFloat(J(item).attr('data-lat')),
                            lng = parseFloat(J(item).attr('data-lng')),
                            id = J(item).attr('data-id'),
                            itemPostcode = J(item).attr('data-postcode').toString(),
                            text = J(item).children('.item-info').html();
                            text += J('.__store-days[data-id="'+id+'"]').html();

                        var defaultIcon = L.icon({
                            popupAnchor: [0, 0],
                            iconUrl: window.location.origin+'/modules/qsuppliers/assets/img/icon-marker.svg',
                            iconSize: [40, 40], // size of the icon
                        });

                        var marker = L.marker([lat, lng], {title: index, icon: defaultIcon}).addTo(map).bindPopup(text);

                        itemMarkers.push(marker);

                        if (!postcode) {
                            markers.push([lat, lng]);
                            J(item).show();
                        } else {

                            postcode = postcode.toString();// >> -- Le code postal recherché --

                            // >> -- Si le code postal est enregistré pour emporter --
                            if (arrayPostcodeStore.indexOf((postcode.toString())) != -1) {
                                markers.push([lat, lng]);
                                J(item).show();
                            }
                        }
                    }

                    count++;

                    if (count === itemLength) {

                        if (markers.length) {

                            var bounds = L.latLngBounds(markers);

                            map.fitBounds(bounds);

                            markerFunction(J('.qsuppliers-item:visible').first().attr('data-index'));

                            J('.qsuppliers-no-shop').hide();
                            J('.qsuppliers-label').show();
                            J('.qsuppliers-stores-choice-form button[type="submit"]').show();
                            J('.qsuppliers-map').show();
                            J('.qsuppliers-stores-choice-content').removeClass('active');
                        } else {
                            J('.qsuppliers-no-shop').show();
                            J('.qsuppliers-label').hide();
                            J('.qsuppliers-stores-choice-form button[type="submit"]').hide();
                            J('.qsuppliers-map').hide();
                            J('.qsuppliers-stores-choice-content').addClass('active');
                        }
                        return callback(map);
                    }
                });

                Jd.off('click', '.qsuppliers-item').on('click', '.qsuppliers-item', function () {

                    J('.qsuppliers-item').removeClass('qsuppliers-item-selected');
                    J(this).addClass('qsuppliers-item-selected');
                    markerFunction(J(this).attr('data-index'));
                });

            } else {

                return callback(map);
            }
        } else {
            return callback(false);
        }
    }
}
module.exports = Map;