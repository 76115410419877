let Checkout = {

    Init : () => {
        Checkout.AddressOption();
        Checkout.DeliveryOptions();
    },

    AddressOption : () => {

        if (J('body').attr('id') === 'checkout' && J('.js-address-item').length) {

            if (J('#checkout-addresses-step').hasClass('js-current-step')) {
                
                if (J('#delivery-addresses').children('.js-address-item').length) {
                    J('#delivery-addresses').children('.js-address-item').first().find('label').click();
                }

                if (J('#invoice-addresses').children('.js-address-item').length) {
                    J('#invoice-addresses').children('.js-address-item').first().find('label').click();
                }
            }
        }
    },

    DeliveryOptions : () => {

        if (J('body').attr('id') === 'checkout' && J('.js-delivery-option').length) {

            if (J('#checkout-delivery-step').hasClass('js-current-step')) {
                J('.js-delivery-option').first().find('label').click();
            }

            if (J('.js-delivery-option[carrier-name="le plateau de l\'écailler"]').length) {
                J('.js-delivery-option[carrier-name="le plateau de l\'écailler"]').find('.carrier-name').text(J('.qstore-name').text());
            }
        }
    }
}
module.exports = Checkout;